import React, { FC } from "react";

import withApolloClient from "@/lib/with-apollo-client";

import CommentIcon from "../../assets/CommentIcon";
import { useGetPostCommentsQuery } from "./index.generated";

import css from "./index.module.css";

type Props = {
  postId: number;
};

const PostComments: FC<Props> = ({ postId }) => {
  const { data, loading } = useGetPostCommentsQuery({
    variables: {
      id: postId.toString(),
    },
  });

  const comments = data?.comments?.list?.total;
  return (
    <div className={css.comments}>
      <CommentIcon />
      {data && !loading && <span className={css.count}>{comments}</span>}
    </div>
  );
};

export default withApolloClient(PostComments);
